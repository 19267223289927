import React from 'react'
import { twMerge } from 'tailwind-merge'

// eslint-disable-next-line react/prop-types
const ViewWrapper = React.forwardRef(({ children, className }, ref) => (
  <div
    ref={ref}
    className={twMerge('grid grow relative p-4 overflow-x-hidden', className)}
  >
    {children}
  </div>
))

ViewWrapper.displayName = 'ViewWrapper'

export default ViewWrapper
