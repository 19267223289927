/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  HeroIcon,
  Text,
  useModal,
} from '@logtrade-technology-ab/logtrade-react-components'
import Lottie from 'lottie-react'
import { ErrorModal, PHButton, Pulse } from '../../elements'
import lockAnimation from './lockAnimation.json'
import useTimedState from '../../../features/useTimedState'
import { useNavbar } from '../../layout/NavbarContext'
import LockDrawer from '../../elements/LockDrawer'
import api from '../../../api'

// Note that values 0 and 14 are first and last frame of the animation subsegment

const SuccessIcon = () => (
  <div className="flex items-center justify-center absolute inset-0 bg-inherit [border-radius:inherit]">
    <HeroIcon icon="checkCircle" className="h-6 text-status-success-1" />
  </div>
)

const messages = {
  onCancelHold: 'Press and hold to connect to the device...',
  onStartRequest: 'Pending...',
  onOpened: <Text className="text-status-success-1">Access Granted</Text>,
  onLocked: <Text className="text-status-success-1">Locked Successfully</Text>,
}

const LocationDetails = ({ location }) => {
  const { unlockableLocks, name, address1 } = location

  const [lock, setLock] = useState(unlockableLocks?.[0])
  const { showModal } = useModal()
  const { setNavbar } = useNavbar()
  const navigate = useNavigate()

  const lockRef = useRef(null)
  const [message, setMessage] = useTimedState('')
  const [locking, setLocking] = useState(false)
  const [opening, setOpening] = useState(false)
  const [locked, setLocked] = useState(false)
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    setNavbar({
      left: (
        <HeroIcon
          icon="arrowLeft"
          className="h-6 mt-1"
          onClick={() => navigate('..')}
        />
      ),
      right:
        unlockableLocks.length > 1 ? (
          <div className="flex items-center">
            <HeroIcon
              icon="lockClosed"
              className="h-5 mr-0"
              onClick={() =>
                showModal(LockDrawer, { locks: unlockableLocks, setLock, lock })
              }
            />
            {unlockableLocks.length}
          </div>
        ) : (
          ''
        ),
    })
  }, [])

  const resetStates = () =>
    [setLocking, setOpening, setLocked, setOpened].forEach((setter) =>
      setter(false)
    )

  const onCancelHold = () => {
    if (!message && !locking && !opening) {
      const msg = messages.onCancelHold
      setMessage(msg, 3000)
    }
  }

  const initiateLockIcon = (open) =>
    lockRef.current?.goToAndStop(open ? 14 : 0, true)

  const animateLockIcon = (open) => {
    lockRef.current?.setDirection(open ? -1 : 1)
    lockRef.current?.goToAndPlay(open ? 14 : 0, true)
  }

  const showError = (title, content) => {
    resetStates()
    setMessage('')
    showModal(ErrorModal, { title, content })
  }

  const onAction = async (open) => {
    if (!navigator.onLine) {
      showError(
        'Network error',
        'You are currently offline. Please make sure you have internet access and try again.'
      )

      return
    }

    resetStates()
    setOpening(open)
    setLocking(!open)
    setMessage(messages.onStartRequest)
    initiateLockIcon(open)

    try {
      const action = open ? api.Locks.unlock : api.Locks.lock
      const { allowed, disallowedReason } = await action({
        lockIolId: lock.iolIdentity,
      })

      if (!allowed) {
        showError(
          'Not allowed',
          `This lock cannot be ${open ? 'unlocked' : 'locked'} right now${
            disallowedReason ? ` (reason: ${disallowedReason})` : ''
          }`
        )
      } else {
        // Subscribe to lockid and wait for event
        const obs = api.Locks.subscribeLock({ lockIolIds: [lock.iolIdentity] })
        const sub = obs.subscribe(({ data }) => {
          const { locked, errorMessage } = data.lockUpdated

          if (locked === undefined || locked === null) {
            showError(
              'Access denied',
              errorMessage ??
                'The operator did not grant you access for this device.'
            )
          } else if (open && !locked) {
            setOpening(false)
            setOpened(true)
            animateLockIcon(true)
            setMessage(messages.onOpened, 3000)
            setTimeout(() => setOpened(false), 3000)
          } else if (!open && locked) {
            setLocking(false)
            setLocked(true)
            animateLockIcon(false)
            setMessage(messages.onLocked, 3000)
            setTimeout(() => setLocked(false), 3000)
          } else {
            showError(
              'Caution',
              `The action you initiated had an opposite effect. The lock is now ${
                locked ? 'LOCKED' : 'OPEN'
              }!`
            )
          }

          sub.unsubscribe()
        })
      }
    } catch (err) {
      showError(
        'Something went wrong',
        'The app encountered an error, please try again.'
      )
    }
  }

  return (
    <div className="grid grow p-6 gap-5 grid-cols-2 grid-rows-[1fr_2fr_auto] items-center overflow-hidden">
      <div className="col-span-full self-center">
        <Text tag="h3" className="text-center">
          {name}
        </Text>
        <Text className="text-center">{address1}</Text>
      </div>
      <div className="grid col-span-full gap-6 h-full justify-items-center grid-rows-[auto_1fr] relative">
        <Pulse active={locking || opening} className="mt-[42px]" />
        <Lottie
          style={{
            height: 180,
            opacity: locking || locked || opening || opened ? 1 : 0.4,
          }}
          animationData={lockAnimation}
          loop={false}
          autoplay={false}
          lottieRef={lockRef}
          initialSegment={[0, 14]}
        />
        <div className="flex text-base">{message ? message : <>&nbsp;</>}</div>
        {/* <AlertBadge
          className="mx-6 my-auto"
          title="Action required"
          content="This location’s operator requires that this lock must be locked before proceeding to the drop off location."
        /> */}
      </div>
      <PHButton
        className="justify-self-end w-[130px]"
        isDisabled={opening}
        active={locking || locked}
        onCancel={onCancelHold}
        onActivate={() => onAction(false)}
        isLoading={locking}
      >
        Lock
        {locked && <SuccessIcon />}
      </PHButton>
      <PHButton
        className="w-[130px]"
        isDisabled={locking}
        active={opening || opened}
        onCancel={onCancelHold}
        onActivate={() => onAction(true)}
        isLoading={opening}
      >
        Unlock
        {opened && <SuccessIcon />}
      </PHButton>
    </div>
  )
}

export default LocationDetails
