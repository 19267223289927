import React, { useRef, useCallback, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ZXing from './zxing_reader'

let zxing
ZXing().then((instance) => (zxing = instance))

const format = 'CODE_39|CODE_128|QR_CODE' // 'EAN_13|EAN_8|CODE_39|CODE_93|CODE_128|UPC_A|UPC_E|QR_CODE'

export const ScannerMode = {
  QRCode: 'qr',
  Barcode: 'barcode',
}

const scanBarcode = (imageData) => {
  const sourceBuffer = imageData.data
  const buffer = zxing._malloc(sourceBuffer.byteLength)
  zxing.HEAPU8.set(sourceBuffer, buffer)
  const result = zxing.readBarcodeFromPixmap(
    buffer,
    imageData.width,
    imageData.height,
    true,
    format
  )

  zxing._free(buffer)
  return result
}

const cropVideo = ({ videoWidth, videoHeight }, mode) => {
  if (mode === ScannerMode.Barcode) {
    const xOffset = videoWidth * 0.05
    const yOffset = videoHeight * 0.375
    const width = videoWidth * 0.9
    const height = videoHeight * 0.25

    return { xOffset, yOffset, width, height }
  }

  if (mode === ScannerMode.QRCode) {
    const xOffset = videoWidth * 0.15
    const yOffset = videoHeight * 0.15
    const width = videoWidth * 0.7
    const height = videoHeight * 0.7

    return { xOffset, yOffset, width, height }
  }
}

// Must be 1:1
const vidConfig = {
  width: 640,
  height: 640,
}

// --------------------------------------------------

let lastTick = 0

const Scanner = ({
  debug = false,
  acceptCodes,
  mode: initMode = ScannerMode.QRCode,
  onSuccess = (data) => console.log(data.text),
  interval = 200,
  size = 400,
  ...props
}) => {
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const labelRef = useRef(null)
  const overlayRef = useRef(null)

  const [mode, setMode] = useState(ScannerMode.QRCode)

  const ratioAdjustment = 120
  const vidCanvasRatio = size / (vidConfig.width - ratioAdjustment)

  const cropped = cropVideo(
    {
      videoWidth: vidConfig.width,
      videoHeight: vidConfig.height,
    },
    mode
  )

  const handleScan = (barcode) => {
    const noValue = 'border-main-10/20'
    const okValue = 'border-status-success-1'
    const bdValue = 'border-status-danger-1'

    if (overlayRef?.current && labelRef?.current) {
      if (barcode?.text?.length) {
        const text = barcode.text
        labelRef.current.innerHTML = text
        overlayRef.current.classList.remove(noValue)

        const accepted =
          mode !== ScannerMode.Barcode ||
          (acceptCodes?.filter((v) => text.startsWith(v)).length ?? true)

        if (accepted) {
          overlayRef.current.classList.add(okValue)
          return onSuccess(barcode)
        }

        overlayRef.current.classList.add(bdValue)
        return
      }

      labelRef.current.innerHTML = ''
      overlayRef.current.classList.add(noValue)
      overlayRef.current.classList.remove(okValue)
      overlayRef.current.classList.remove(bdValue)
    }
  }

  const tick = useCallback(() => {
    if (!canvasRef.current || !videoRef.current) return

    if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
      const tickTime = new Date().getTime()

      const croppedVideo = cropVideo(videoRef.current, mode)
      canvasRef.current.height = croppedVideo.height
      canvasRef.current.width = croppedVideo.width

      const ctx = canvasRef.current.getContext('2d', {
        willReadFrequently: true,
      })

      ctx.drawImage(
        videoRef.current,
        croppedVideo.xOffset,
        croppedVideo.yOffset,
        croppedVideo.width,
        croppedVideo.height,
        0,
        0,
        croppedVideo.width,
        croppedVideo.height
      )

      if (tickTime - lastTick >= interval) {
        const barcode = scanBarcode(
          ctx.getImageData(0, 0, croppedVideo.width, croppedVideo.height)
        )

        handleScan(barcode)
        lastTick = tickTime
      }
    }

    requestAnimationFrame(tick)
  }, [mode, interval, onSuccess])

  useLayoutEffect(() => {
    const videoElement = videoRef.current

    const init = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          width: { ideal: vidConfig.width },
          height: { ideal: vidConfig.height },
        },
      })

      if (videoElement) {
        videoElement.srcObject = stream
        requestAnimationFrame(tick)
      }
    }

    if (videoElement && !videoElement.srcObject) {
      init()
    } else {
      return () => {
        if (videoElement) {
          const stream = videoElement.srcObject
          if (stream) {
            const tracks = stream.getTracks()
            tracks.forEach((track) => track.stop())
            videoElement.srcObject = null
          }
        }
      }
    }
  }, [])

  return (
    <div {...props}>
      <div
        className="relative flex justify-center items-center overflow-hidden rounded-xl"
        style={{ width: size, height: size }}
        onClick={() =>
          setMode((m) =>
            m === ScannerMode.QRCode ? ScannerMode.Barcode : ScannerMode.QRCode
          )
        }
      >
        <div
          ref={overlayRef}
          className="absolute z-[2] border-4 rounded-md box-border border-main-10/20"
          style={{
            width: (cropped.width * vidCanvasRatio),
            height: (cropped.height * vidCanvasRatio),
          }}
        >
          <div className="relative w-full">
            <span
              ref={labelRef}
              className="absolute top-0 -translate-y-full z-[4] max-w-full px-1 pb-1 text-[5px] text-status-success-0 break-words"
            />
          </div>
        </div>

        <div
          className="absolute z-[3]"
          style={{ transform: `scale(${vidCanvasRatio})` }}
        >
          <canvas
            ref={canvasRef}
            className={`border-2 rounded-md box-border border-status-warning-1 ${
              !debug && 'hidden'
            }`}
          />
        </div>

        <video
          ref={videoRef}
          muted
          autoPlay
          playsInline
          className="w-full h-full relative block z-0"
        />
      </div>
    </div>
  )
}

Scanner.propTypes = {
  debug: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(ScannerMode)),
  onSuccess: PropTypes.func,
  interval: PropTypes.number,
  size: PropTypes.number,
  acceptCodes: PropTypes.arrayOf(PropTypes.string),
}

export default Scanner
