import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import {
  Modal,
  Text,
  HeroIcon,
  Button,
} from '@logtrade-technology-ab/logtrade-react-components'

const LockDrawer = ({ locks, lock, setLock, onClose, ...props }) => {
  const onLockClick = (lock) => {
    setLock(lock)
    onClose()
  }

  return (
    <Modal
      title="Select a device at this location"
      onClose={onClose}
      isDismissable
      className="self-end !w-full !h-[unset] min-h-[40vh] max-h-full p-2 rounded-t-2xl overflow-y-auto"
      {...props}
    >
      <div className="grid gap-4 p-4 overflow-y-auto">
        {locks.map((cur, index) => (
          <Button
            key={cur.iolIdentity}
            onPress={() => onLockClick(cur)}
            variant="outlined"
          >
            {lock.iolIdentity === cur.iolIdentity && (
              <HeroIcon icon="check" className="h-4 mr-2 inline" />
            )}
            Lock {index + 1}
          </Button>
        ))}
      </div>
    </Modal>
  )
}

LockDrawer.propTypes = {
  locks: PropTypes.array,
  lock: PropTypes.object,
  setLock: PropTypes.func,
  ...Modal.propTypes,
}

export default LockDrawer
