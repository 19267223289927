import React, { createContext, useState, useContext } from 'react'

const getCoordinates = () =>
  new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej)
  })

const PositionContext = createContext({
  coords: null,
  error: null,
  loading: false,
  getPosition: () => null,
})

// eslint-disable-next-line react/prop-types
export const PositionProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [coords, setCoords] = useState(null)
  const [error, setError] = useState(null)

  const getPosition = () => {
    setLoading(true)

    getCoordinates()
      .then(({ coords }) => setCoords(coords))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  return (
    <PositionContext.Provider value={{ coords, error, loading, getPosition }}>
      {children}
    </PositionContext.Provider>
  )
}

export const usePosition = () => useContext(PositionContext)

export const PositionConsumer = PositionContext.Consumer
