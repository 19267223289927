import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const NavbarContext = createContext()

export const useNavbar = () => useContext(NavbarContext)

export const NavbarProvider = ({ children }) => {
  const setNavbar = (newState) =>
    setNavbarState((old) => ({ ...old, ...newState }))

  const [navbarState, setNavbarState] = useState({
    left: null,
    right: null,
    setNavbar,
  })

  return (
    <NavbarContext.Provider value={navbarState}>
      {children}
    </NavbarContext.Provider>
  )
}

NavbarProvider.propTypes = {
  children: PropTypes.any,
}

export const NavbarConsumer = NavbarContext.Consumer
