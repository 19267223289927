import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {
  Login as LTLogin,
  useLogtradeAuth,
} from '@logtrade-technology-ab/logtrade-react-components'

import api from '../../api'

const LoginPage = () => {
  const { isAuthenticated } = useLogtradeAuth()
  const location = useLocation()

  const Login = LTLogin.FullPage

  if (isAuthenticated) {
    const state = location?.state
    const target = state?.from && state.from !== 'logout' ? state.from : '/'
    return <Navigate to={target} />
  }

  const loginRequest = async ({ email, password }) => {
    const { token } = await api.Auth.login({ email, password })
    return token
  }

  return <Login request={loginRequest} />
}

export default LoginPage
