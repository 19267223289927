import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  HeroIcon,
} from '@logtrade-technology-ab/logtrade-react-components'

const ErrorModal = ({ title, content, onClose, ...props }) => (
  <Modal
    onClose={onClose}
    title={
      <div className="flex items-center">
        <HeroIcon
          icon="exclamationCircle"
          className="text-status-error-1 mr-3 h-8"
        />
        {title}
      </div>
    }
    isDismissable={false}
    className="!h-[unset] _sm:!w-[90vw] rounded-3xl"
    {...props}
  >
    <div className="px-9 pb-9">
      {content}
      <div className="flex mt-5">
        <Button className="ml-auto" size="sm" onPress={onClose}>
          Ok
        </Button>
      </div>
    </div>
  </Modal>
)

ErrorModal.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node,
  ...Modal.propTypes,
}

export default ErrorModal
