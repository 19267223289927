import React, { useEffect, useRef } from 'react'
import { useNavbar } from '../../layout/NavbarContext'
import { useNavigate } from 'react-router-dom'
import { ViewWrapper } from '../../elements'
import {
  HeroIcon,
  Text
} from '@logtrade-technology-ab/logtrade-react-components'

const LockAssistInvalidQR = () => {

  const navigate = useNavigate()
  const { setNavbar } = useNavbar()
  const viewRef = useRef(null)

  useEffect(() => {
    setNavbar({
      left: (
        <HeroIcon
          icon="arrowLeft"
          className="h-6 mt-1"
          onClick={() => navigate('..')}
        /> 
      )
    })
  }, [])

  return (
      <ViewWrapper
        ref={viewRef}
        className="grid-rows-[40px_auto_auto_1fr] w-full overflow-x-hidden justify-center"
      >
        <Text tag="h6" className="text-center mb-3">
          Please call the following number to reach out to a 24/7 support center:
        </Text>

        <div className="flex items-center w-full justify-center mt-6">
            <HeroIcon
              icon="phone"
              className="h-6 mr-2 text-blue-500"
              color='secondary'
            />
            <Text tag="h6" className="text-center text-blue-500 font-bold" >+46 709 412 012</Text>
        </div>
    </ViewWrapper>
  )
}

export default LockAssistInvalidQR
