export const omit = (object, field) =>
  Object.keys(object).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: curr !== field ? object[curr] : undefined,
    }),
    {}
  )

export const range = (size, startAt = 0) =>
  [...Array(size).keys()].map((i) => i + startAt)
