import { useQuery } from '@apollo/client'
import { GetMeQuery } from './queries'
export * from './queries'

const api = {
  // Get profile
  get: (options) => {
    const state = useQuery(GetMeQuery, options)
    const profile = state.data?.me ?? {}

    return [profile, state]
  },
}

export default api
