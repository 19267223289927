import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { range } from '../../utils'
import { twMerge } from 'tailwind-merge'

const pulse = (order) => keyframes`
  0% {
    transform: scale(0.95);
  }

  95% {
    transform: scale(${Math.pow(1.5, order)});
    opacity: ${order ? 0 : 1};
  }

  100% {
    transform: scale(0.9);
    opacity: ${order ? 0 : 1};
  }
`

const PulseStyle = styled('div')`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
  top: 45px;
  border-radius: 50%;
  opacity: ${({ order }) => 0.8 - order * 0.1};
  animation: ${({ order }) => pulse(order)} 1.8s
    cubic-bezier(0.3, 0.6, 0.8, 0.75);
  animation-delay: ${({ order }) => Math.pow(order, 3)}ms;
  animation-iteration-count: infinite;
`

const Pulse = ({ order = 6, className, ...props }) =>
  range(order).map((n) => (
    <PulseStyle
      className={twMerge('bg-accent-6', className)}
      key={n}
      order={n}
      {...props}
    />
  ))

Pulse.propTypes = {
  range: PropTypes.number,
  className: PropTypes.string,
}

export default Pulse
