import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavbar } from '../../layout/NavbarContext'
import { useNavigate } from 'react-router-dom'
import { ViewWrapper } from '../../elements'
import {
  HeroIcon,
  Text
} from '@logtrade-technology-ab/logtrade-react-components'
import md5 from 'md5'

const LockAssistValidTU = ({TUIolId}) => {
  
  const navigate = useNavigate()
  const { setNavbar } = useNavbar()
  const viewRef = useRef(null)

  const GeneratePin = (iolId) =>
  {
    var hash = md5(iolId)
    return hash.replace(/\D/g,'').substring(0,6)
  }

  const pin = GeneratePin(TUIolId)

  useEffect(() => {
    setNavbar({
      left: (
        <HeroIcon
          icon="arrowLeft"
          className="h-6 mt-1"
          onClick={() => navigate('..')}
        /> 
      )
    })
  }, [])

  return (
      <ViewWrapper
        ref={viewRef}
        className="grid-rows-[40px_auto_auto_1fr] w-full overflow-x-hidden justify-center"
      >
        <Text tag="h6" className="text-center mb-3">
          Please call the following number to reach out to a 24/7 support center:
        </Text>

        <div className="flex items-center w-full justify-center mt-6">
            <HeroIcon
              icon="phone"
              className="h-6 mr-2 text-blue-500"
            />
            <Text tag="h6" className="text-center text-blue-500 font-bold">+46 709 412 012</Text>
        </div>

        <div className="flex flex-col justify-center items-center w-full mt-4">
          <Text className="text-center">Please provide the following pin when asked:</Text>
          <Text tag="h6" className="text-center mt-1 text-blue-500 font-bold">{pin}</Text>
          <Text className="text-center mt-1">The consultant will provide all the information you need</Text>
        </div>
    </ViewWrapper>
  )
}

LockAssistValidTU.propTypes = {
  TUIolId: PropTypes.string.isRequired
}

export default LockAssistValidTU
