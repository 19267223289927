import { useQuery } from '@apollo/client'
import { GetLocationsQuery } from './queries'
export * from './queries'

const api = {
  // Get single location by IOL ID
  getByIolId: (iolId, options) => {
    const [locations, state] = api.getAll(options)
    const location = locations?.find((l) => l.iolIdentity === iolId)

    return [location, state]
  },

  // Get all locations
  getAll: (options) => {
    const state = useQuery(GetLocationsQuery, options)
    const locations = state.data?.unlockableLocations ?? []

    return [locations, state]
  },

  getAllWithLocks: (options) => {
    const [locations, state] = api.getAll(options)
    const withLocks = locations.filter(
      (location) => location.unlockableLocks.length
    )

    return [withLocks, state]
  },
}

export default api
