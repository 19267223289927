import React from 'react'
import { Loader } from '@logtrade-technology-ab/logtrade-react-components'

const Loading = () => (
  <div className="flex justify-center items-center grow">
    <Loader className="h-12" />
  </div>
)

export default Loading
