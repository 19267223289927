import AuthAPI from './auth'
import ProfileAPI from './profile'
import LocationAPI from './locations'
import LockAPI from './locks'
import TradeUnitsAPI from './tradeunits'

import { setToken, restore, purge } from './client'

export default {
  Auth: AuthAPI,
  Profile: ProfileAPI,
  Locations: LocationAPI,
  Locks: LockAPI,
  TradeUnits: TradeUnitsAPI,
  restore,
  purge,
  setToken,
}
