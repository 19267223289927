import moment from 'moment'
import React from 'react'

// ---------------------- Strings -----------------------

export const capitalize = (word) =>
  word
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ')

// Map values to joined string
export const listString =
  (prop, none = 'None', join = '\n') =>
  (arr) =>
    arr?.length ? arr.map((v) => v[prop]).join(join) : none

// --------------------- Date and time ------------------

moment.locale('sv')

export const dayMonth = (date) => moment(date).format('ll')

export const dateAndTime = (date) => {
  return `${moment(date).format('YYYY-MM-DD')} ${moment(date).format('LT')}`
}

export const dateOnly = (date) => moment(date).format('YYYY-MM-DD')

export const weekday = (date) => {
  const wd = new Date(date)
    .toLocaleTimeString(moment.locale(), { weekday: 'long' })
    .split(' ')[0]
  return wd.charAt(0).toUpperCase() + wd.slice(1)
}

// --------------------- Addresses ---------------------

const spaceOrBreak = (lines, line) => (line >= lines ? <>&nbsp;</> : <br />)

export const fullAddress = (
  { address1, zipCode, city, countryName }, // Address properties
  lines = 2
) => (
  <>
    {[
      `${address1}${lines === 1 ? ',' : ''}`,
      [zipCode, city].filter(Boolean).join(', '),
      countryName,
    ]
      .filter(Boolean)
      .map((value, i) => (
        <span key={i}>
          {capitalize(value)}
          {spaceOrBreak(lines, i + 1)}
        </span>
      ))}
  </>
)
