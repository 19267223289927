import React from 'react'
import styled from 'styled-components'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  Navbar,
  Text,
  HeroIcon,
  AppWrap,
} from '@logtrade-technology-ab/logtrade-react-components'
import { NavbarProvider, useNavbar } from './NavbarContext'
import api from '../../api'

const App = styled(AppWrap)`
  user-select: none;
`

const Layout = () => {
  const [profile] = api.Profile.get()
  const navigate = useNavigate()

  const { left, right } = useNavbar()

  return (
    <App className="flex flex-col bg-main-10 pt-14">
      <Navbar
        size="sm"
        title={left ?? 'Locks App'}
        user={
          right ?? (
            <div className="flex items-center">
              <Text className="whitespace-nowrap text-xs">
                {profile.username}
              </Text>
              <HeroIcon
                icon="logout"
                className="pl-2 h-5 cursor-pointer"
                onClick={() => navigate('/logout')}
              />
            </div>
          )
        }
      />
      <Outlet />
    </App>
  )
}

const WrappedLayout = () => (
  <NavbarProvider>
    <Layout />
  </NavbarProvider>
)

export default WrappedLayout
