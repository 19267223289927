import { gql } from '@apollo/client'

export const LockSubscription = gql`
  subscription Lock($lockIolIds: [String!]) {
    lockUpdated(lockIolIds: $lockIolIds) {
      locked
      errorMessage
    }
  }
`

LockSubscription.input = {
  lockIolIds: true,
}
