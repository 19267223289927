import { gql } from '@apollo/client'

export const TradeUnitFragment = gql`
  fragment TradeUnitFragment on TradeUnit {
    barcodeIdentity
    createdAt
    description
    id
    iolIdentity
    itemIdentity
  }
`

export const GetTradeUnitsQuery = gql`
  ${TradeUnitFragment}

  query GetTradeUnits {
    tradeUnits {
      ...TradeUnitFragment
    }
  }
`
