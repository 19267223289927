import superagent from 'superagent'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  HttpLink,
} from '@apollo/client'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

let token = ''

export const setToken = (_token) => {
  token = _token
}

// eslint-disable-next-line no-undef
const getAPIDomain = () => process.env.REACT_APP_CG_API_DOMAIN

const API_ROOT = (version) => `https://${getAPIDomain()}/api/${version}`
const GQL_ROOT = () => `https://${getAPIDomain()}/graphql`
const SUB_ROOT = () => `wss://${getAPIDomain()}/subscriptions`

const getEndpoint = (path, version = 1) => `${API_ROOT(version)}${path}`
const responseBody = (res) => res.body

const httpLink = createHttpLink({
  uri: GQL_ROOT,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: SUB_ROOT,
    connectionParams: () => ({
      Authorization: token ? `Bearer ${token}` : '',
    }),
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// Initialize apollo client with persistent cache
const cache = new InMemoryCache()
const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  debug: false,
})

export let apolloClient = null

// Init must be called before using apolloClient,
// preferrably before any api call dispatches
export const restore = async () => {
  await persistor.restore()

  apolloClient = new ApolloClient({
    link: authLink.concat(splitLink),
    defaultOptions,
    cache,
  })

  return apolloClient
}

// Call purge on logout to remove any cached data
export const purge = () => {
  apolloClient.clearStore()
  persistor.purge()
}

const addToken = (passedToken) => (req) => {
  if (token) {
    req.set('Authorization', `Bearer ${token}`)
  }

  if (passedToken) {
    req.set('Authorization', `Bearer ${passedToken}`)
  }
}

export const requests = {
  del: (path, passedToken) =>
    superagent
      .del(getEndpoint(path))
      .use(addToken(passedToken))
      .then(responseBody),
  get: (path, passedToken) =>
    superagent
      .get(getEndpoint(path))
      .use(addToken(passedToken))
      .then(responseBody),
  put: (path, body, passedToken) =>
    superagent
      .put(getEndpoint(path), body)
      .use(addToken(passedToken))
      .then(responseBody),
  postRaw: (path, body, passedToken) =>
    superagent
      .post(getEndpoint(path), body)
      .accept('json')
      .use(addToken(passedToken)),
  post: (path, body, passedToken) =>
    requests.postRaw(path, body, passedToken).then(responseBody),
}
