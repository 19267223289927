import React from 'react'
import LockAssistScan from './LockAssistScan'
import LockAssistValidTU from './LockAssistValidTU'
import LockAssistInvalidQR from './LockAssistInvalidQR'
import { useSearchParams } from 'react-router-dom'

const ScanTradeUnit = () => {
  return <LockAssistScan />
}

const LockHelp = () => {

    const [params] = useSearchParams()
    const iolId = params.get('iolId')

    if (iolId !== null) return <LockAssistValidTU TUIolId ={iolId} />
    else return <LockAssistInvalidQR />
}

export default {
  ScanTradeUnit,
  LockHelp
}