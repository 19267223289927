import { gql } from '@apollo/client'

export const ProfileFragment = gql`
  fragment ProfileFragment on User {
    id
    username
    email
    role
  }
`

/**
 * Get self (user)
 */
export const GetMeQuery = gql`
  ${ProfileFragment}

  query GetMe {
    me {
      ...ProfileFragment
    }
  }
`
