import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { getDistance } from 'geolib'
import {
  Text,
  HeroIcon,
} from '@logtrade-technology-ab/logtrade-react-components'
import { fullAddress } from '../../../utils'
import { useNavbar } from '../../layout/NavbarContext'
import { twMerge } from 'tailwind-merge'
import { usePosition } from '../../../features/PositionContext'

const getDistanceString = (location) => {
  const { distance: inMeters } = location
  const unit = inMeters > 1000 ? 'km' : 'm'
  const distance = Math.round(inMeters / (unit === 'km' ? 1000 : 1))
  return `${distance} ${unit}`
}

const locationItemStyle = twMerge(
  'flex items-center border-b cursor-pointer bg-main-10 rounded'
)

const cornerDistanceStyle = twMerge(
  'absolute top-5 right-5 text-main-5 text-xs'
)

const HighlightItem = ({ location, ...props }) => (
  <div className="flex p-2 bg-main-1 flex-col relative">
    <div
      className={twMerge(
        locationItemStyle,
        'flex-col p-3 items-center justify-center'
      )}
      {...props}
    >
      {/* continue here */}
      <div className={cornerDistanceStyle}>{getDistanceString(location)}</div>
      <div className="text-base font-normal mb-1">
        <HeroIcon
          icon="locationMarker"
          variant="contained"
          className="inline h-4 mr-2 mb-1"
        />
        {location.name}
      </div>
      <Text className="text-xs mb-1">{fullAddress(location, 1)}</Text>
      <Text className="text-xs">
        {location.unlockableLocks.length} device
        {location.unlockableLocks.length > 1 && 's'}
      </Text>
    </div>
  </div>
)

const LocationItem = ({ location, ...props }) => (
  <div className={twMerge(locationItemStyle, 'p-4')} {...props}>
    <HeroIcon icon="locationMarker" className="h-9 mr-4" />
    <div className="flex flex-col grow">
      <Text className="text-sm font-normal mb-1">{location.name}</Text>
      <Text className="text-xs">{fullAddress(location, 1)}</Text>
    </div>
    <Text className="font-xs text-main-5">{getDistanceString(location)}</Text>
  </div>
)

HighlightItem.propTypes = {
  location: PropTypes.object,
}

LocationItem.propTypes = HighlightItem.propTypes

const LocationOverview = ({ locations }) => {
  const navigate = useNavigate()
  const {
    coords: { longitude, latitude },
  } = usePosition()
  const { setNavbar } = useNavbar()

  useEffect(() => {
    setNavbar({
      left: 'Locations',
      right: undefined,
    })
  }, [])

  const locationsWithDistance = useMemo(
    () =>
      locations
        .map((location) => {
          const distance = getDistance(
            { longitude, latitude },
            location.geoLocation
          )

          return { ...location, distance }
        })
        .sort((a, b) => a.distance - b.distance),
    [locations, longitude, latitude]
  )

  const [closest] = locationsWithDistance
  const moreThanOne = locationsWithDistance.length > 1

  return (
    <>
      {moreThanOne && (
        <HighlightItem
          location={closest}
          onClick={() =>
            navigate(
              `/location?${new URLSearchParams({
                iolId: closest.iolIdentity,
              }).toString()}`
            )
          }
        />
      )}
      {locationsWithDistance.map((location) => (
        <LocationItem
          key={location.iolIdentity}
          location={location}
          onClick={() =>
            navigate(
              `/location?${new URLSearchParams({
                iolId: location.iolIdentity,
              }).toString()}`
            )
          }
        />
      ))}
     
      <HeroIcon
          icon="question-mark-circle"
          variant="contained"
          className="absolute bottom-0 right-0 mr-2 mb-2 h-10 w-10"
          onClick={() =>
            navigate('/lockassist')
          }
      />
    </>
  )
}

LocationOverview.propTypes = {
  locations: PropTypes.array.isRequired,
}

export default LocationOverview
