import { gql } from '@apollo/client'

export const LockSmartlockMutation = gql`
  mutation LockSmartlock($data: LockSmartlockInput!) {
    lockSmartlock(data: $data) {
      allowed
      disallowedReason
    }
  }
`

LockSmartlockMutation.input = {
  lockIolId: true,
}

export const UnlockSmartlockMutation = gql`
  mutation UnlockSmartlock($data: UnlockSmartlockInput!) {
    unlockSmartlock(data: $data) {
      allowed
      disallowedReason
    }
  }
`

UnlockSmartlockMutation.input = {
  lockIolId: true,
}
