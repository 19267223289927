import { useQuery } from '@apollo/client'
import { GetTradeUnitsQuery } from './queries'

const api = {
  // Get single tradeunit by IOL ID
  getByIolId: (iolId, options) => {
    const [tradeunits, state] = api.getAll(options)
    const tradeunit = tradeunits?.find((l) => l.iolIdentity === iolId)
    return [tradeunit, state]
  },

   // Get all trade units
   getAll: (options) => {
    
    const state = useQuery(GetTradeUnitsQuery, options)
    const tradeUnits = state.data?.tradeUnits ?? []

    return [tradeUnits, state]
  },
}

export default api
