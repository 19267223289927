import React from 'react'
import { createRoot } from 'react-dom/client'
import { LogtradeAuthProvider } from '@logtrade-technology-ab/logtrade-react-components'
import App from './App'
import api from './api'

import './index.css'
import '@logtrade-technology-ab/logtrade-react-components/lib.css'
import { ApolloProvider } from '@apollo/client'

const container = document.getElementById('root')
const root = createRoot(container)

api.restore().then((client) => {
  root.render(
    <ApolloProvider client={client}>
      <LogtradeAuthProvider>
        <App />
      </LogtradeAuthProvider>
    </ApolloProvider>
  )
})
