import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import AuthGate from './features/AuthGate'
import * as Pages from './components/pages'
import Layout from './components/layout/Layout'
import {
  useLogtradeAuth,
  ModalProvider,
  ModalRoot,
} from '@logtrade-technology-ab/logtrade-react-components'
import api from './api'
import { PositionProvider } from './features/PositionContext'

export function App() {
  const { isAuthenticated, token, signOut } = useLogtradeAuth()

  useEffect(() => {
    if (isAuthenticated) api.setToken(token)
  }, [isAuthenticated, token])

  const Logout = () => {
    useEffect(() => {
      api.purge()
      signOut()
    }, [])

    return <Navigate to="/login" />
  }

  return (
    <PositionProvider>
      <ModalProvider>
        <ModalRoot />
        <React.StrictMode>
          <Router>
            <Routes>
              <Route path="login" element={<Pages.Login />} />
              <Route element={<AuthGate />}>
                <Route path="logout" element={<Logout />} />
                <Route element={<Layout />}>
                  <Route
                    path="locations"
                    element={<Pages.Locations.Overview />}
                  />
                  <Route
                    path="location"
                    element={<Pages.Locations.Details />}
                  />
                  <Route path="lockassist">
                    <Route path="help" element={<Pages.LockAssist.LockHelp />} />
                    <Route path="" element={<Pages.LockAssist.ScanTradeUnit />} />
                  </Route>
                </Route>
                <Route path="*" element={<Navigate to="locations" replace />} />
              </Route>
            </Routes>
          </Router>
        </React.StrictMode>
      </ModalProvider>
    </PositionProvider>
  )
}

export default App
