import { apolloClient } from '../client'
import { LockSmartlockMutation, UnlockSmartlockMutation } from './mutations'
import { LockSubscription } from './subscriptions'
import { parseResult, conform } from '../utils'

const api = {
  lock: async (data) => {
    const res = await apolloClient.mutate({
      mutation: LockSmartlockMutation,
      variables: {
        data: conform(data, LockSmartlockMutation.input),
      },
    })

    return parseResult(res, 'lockSmartlock')
  },
  unlock: async (data) => {
    const res = await apolloClient.mutate({
      mutation: UnlockSmartlockMutation,
      variables: {
        data: conform(data, UnlockSmartlockMutation.input),
      },
    })

    return parseResult(res, 'unlockSmartlock')
  },
  subscribeLock: (data) => {
    const observabe = apolloClient.subscribe({
      query: LockSubscription,
      variables: data,
    })

    return observabe
  },
}

export default api
