import { gql } from '@apollo/client'

export const LocationFragment = gql`
  fragment LocationFragment on UnlockableLocation {
    name
    iolIdentity
    address1
    zipCode
    city
    countryCode
    unlockableLocks {
      iolIdentity
    }
    geoLocation {
      lat
      lng
    }
  }
`

export const GetLocationsQuery = gql`
  ${LocationFragment}

  query GetUnlockableLocations {
    unlockableLocations {
      ...LocationFragment
    }
  }
`
