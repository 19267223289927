import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Button } from '@logtrade-technology-ab/logtrade-react-components'
import { usePosition } from '../../../features/PositionContext'
import { CenterMessage } from '../../elements'
import { Loading } from '../../layout'
import LocationDetails from './Details'
import LocationsOverview from './Overview'
import api from '../../../api'

const NoLocations = () => (
  <CenterMessage
    icon="x"
    title="No locks."
    message="It seems like there are no locks tied to any location on this account."
  />
)

const PreLocations = () => {
  const { getPosition, loading } = usePosition()

  return (
    <CenterMessage
      icon="locationMarker"
      title="Let us know where you are"
      message="In order to determine which locks are nearby, we'll need your current
  location. Please click the button below and accept the prompt in your
  browser."
    >
      <Button onPress={() => getPosition()} isLoading={loading}>
        Share Location
      </Button>
    </CenterMessage>
  )
}

const Details = () => {
  const { coords } = usePosition()

  const [params] = useSearchParams()
  const iolId = params.get('iolId')

  const [location, { loading }] = api.Locations.getByIolId(iolId, {
    skip: !coords,
  })

  if (!coords) return <Navigate to=".." replace />

  if (location) return <LocationDetails location={location} />

  if (loading) return <Loading />

  return <Navigate to=".." replace />
}

const Overview = () => {
  const { coords } = usePosition()

  const [withLocks, { loading }] = api.Locations.getAllWithLocks()
  const anyLocations = !!withLocks.length

  if (coords) {
    if (anyLocations) return <LocationsOverview locations={withLocks} />

    return loading ? <Loading /> : <NoLocations />
  }

  return <PreLocations />
}

export default {
  Details,
  Overview,
}
