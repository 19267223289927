import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavbar } from '../../layout/NavbarContext'
import { WasmScanner } from '../../scanner'
import { ViewWrapper } from '../../elements'
import { useNavigate } from 'react-router-dom'
import {
  Text,
  HeroIcon,
  Button
} from '@logtrade-technology-ab/logtrade-react-components'

const LockAssistScan = () => {

  const navigate = useNavigate()
  const { setNavbar } = useNavbar()
  const viewRef = useRef(null)
  const [width, setWidth] = useState(0)

  // Barcodes starting with any of these are accepted
  const barcodeAccept = ['N', 'P', 'S', 'Q']

  const onNewScanResult = ({ text, format }) => {
    
    switch (format) {
      case 'QRCode':

        var tradeUnitIOLId = text.trim().toLowerCase()
        if (tradeUnitIOLId.startsWith('https://iol.logtrade') && tradeUnitIOLId.includes('tradeunit'))
        {
          navigate(
            `/lockassist/help?${new URLSearchParams({
              iolId: tradeUnitIOLId,
            }).toString()}`
          )
        }
        else
        {
          navigate('/lockassist/help')
          console.log('Trade unit iol id is not correct format')
        }
        break

      default:
        console.log('Unknown code type')
    }
  }

  useLayoutEffect(() => {
    setWidth(Math.min((viewRef?.current?.offsetWidth ?? 0) * 0.9, 700))
  }, [])

  useEffect(() => {
    setNavbar({
      left: (
        <div className="flex items-center">
          <HeroIcon
            icon="arrowLeft"
            className="h-6 mr-2"
            onClick={() => navigate('..')}
          /> 
          <Text tag="h6" className="text-center">Help</Text>
        </div>
      ),
      right: undefined,
    })
  }, [])

  return (
      <ViewWrapper
        ref={viewRef}
        className="grid-rows-[40px_auto_auto_1fr] w-full overflow-x-hidden justify-center"
      >

        <Text tag="h6" className="text-center mb-4">
          If you are having problem with opening the lock, please scan the QR code on the shipment label and follow the instructions
        </Text>

        <WasmScanner
          size={width}
          onSuccess={onNewScanResult}
          acceptCodes={barcodeAccept}
          className="mb-3 mt-12 mx-auto"
        />

        <Button onPress={() =>  navigate('/lockassist/help')} className="mx-auto" >
        <HeroIcon
          icon="question-mark-circle"
          variant="contained"
          className="inline h-6 mr-2 mb-1"
        />
        Need Help
        </Button>

       
    </ViewWrapper>
  )
}

export default LockAssistScan