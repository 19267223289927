import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Button } from '@logtrade-technology-ab/logtrade-react-components'
import { twMerge } from 'tailwind-merge'

navigator.vibrate =
  navigator.vibrate ||
  navigator.webkitVibrate ||
  navigator.mozVibrate ||
  navigator.msVibrate

const grow = keyframes`
  0% {
    transform: scale(1);
  } 95% {
    opacity: 0.5;
    transform: scale(1.2, 1.4);
    filter: blur(0);
  } 100% {
    transform: scale(1.4, 1.8);
    opacity: 0;
    filter: blur(2px);
  }
`

const phButtonStyle = (variant) =>
  twMerge(
    'relative z-10',
    variant === 'outlined' && 'bg-main-10',
    'before:content-[""] before:absolute before:inset-0 before:z-[-1] before:bg-inherit',
    'after:content-[""] after:absolute after:inset-0 after:z-[-2] after:bg-accent-6',
    'before:[border-radius:inherit] after:[border-radius:inherit]'
  )
const PHButtonStyle = styled(Button)`
  &[data-pressed='true']::after {
    animation: ${grow} ${({ duration }) => duration}s;
    animation-timing-function: cubic-bezier(0, 0.3, 1, 0.49);
  }
`

const PHButton = ({
  children,
  onActivate,
  onCancel,
  active: _active,
  timeout = 1000,
  className,
  ...props
}) => {
  const [active, setActive] = useState(false)
  const [timeId, setTimeId] = useState(null)

  const activate = () => {
    onActivate()
    navigator.vibrate?.(100)
    setTimeId(null)
  }

  const start = () => {
    if (!timeId) setTimeId(setTimeout(activate, timeout))
    setActive(true)
  }

  const end = () => {
    clearTimeout(timeId)
    if (timeId) onCancel()
    setTimeId(null)
    setActive(false)
  }

  const variant = active || _active ? 'contained' : 'outlined'

  return (
    <PHButtonStyle
      variant={variant}
      className={twMerge(phButtonStyle(variant), className)}
      duration={timeout / 1000}
      onPressStart={start}
      onPressEnd={end}
      {...props}
    >
      {children}
    </PHButtonStyle>
  )
}

PHButton.propTypes = {
  children: PropTypes.node,
  onActivate: PropTypes.func,
  onCancel: PropTypes.func,
  active: PropTypes.bool,
  timeout: PropTypes.number,
  className: PropTypes.string,
}

export default PHButton
