import { useState } from 'react'

const useTimedState = (init) => {
  const [_value, _setValue] = useState({
    value: init,
    id: null,
  })

  const setValue = (value, timeout) => {
    clearTimeout(_value.id)
    _setValue({
      value,
      id: timeout ? setTimeout(() => setValue(''), timeout) : null,
    })
  }

  return [_value.value, setValue]
}

export default useTimedState
