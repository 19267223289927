export const parseResult = (res, dataKey) => {
  if (res.data) return res.data[dataKey]

  if (res.errors) {
    throw new Error(`GQL error ${res.errors.map((e) => e.message).join(' ')}`)
  }

  throw new Error('Unknown error')
}

// The conform function is used to discard additional
// properties from data passed to a mutation, as well
// as check wether all required fields are present in
// the data passed from sagas.

// This ensures that no local apollo properties like
// "__typename", or additional subfields are sent in
// the actual request (resulting in request errors).

// Just as definitions for queries, when using conform
// we have to specify all fields to be sent in mutations.
// Current format is: { propertyName: required (bool) }

// This also allows components to import schemas and easily
// verify wether certain input fields are required or not.

export const conform = (data, schema) => {
  const shaped = {}

  Object.keys(schema).forEach((key) => {
    if (schema[key] && !data[key]) {
      throw new Error(`Internal mutation property ${key}`)
    }

    shaped[key] = data[key]
  })

  return shaped
}
